@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.font-inter {
  font-family: 'Inter', sans-serif;
}

.font-outfit {
  font-family: 'Outfit', sans-serif !important;
}

.font-open {
  font-family: 'Open Sans', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: hsl(218, 54%, 20%);
}

.loader {
  border: 10px solid #fff;
  border-top: 10px solid hsl(232, 100%, 61%);
  border-bottom: 10px solid hsl(232, 100%, 61%);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}


.fastSpin {
    animation: spin 0.3s infinite;
}

.mediumSpin {
    animation: spin 1.5s infinite;
}

.slowSpin {
    animation: spin 2s infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-scroll::-webkit-scrollbar-track {
  background: hsl(232, 100%, 61%);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background: hsl(232, 100%, 61%);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(232, 100%, 61%);
}

.tab-item::after {
  content: "";
  position: absolute;
  top: 25px;
  bottom: 10px;
  right: -3px;
  width: 2px;
  background: hsl(0, 0%, 81%);
}

.tab-item:last-child:after {
  display: none;
}