body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cost-input::-webkit-outer-spin-button,
.cost-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.ag-theme-alpine .ag-header {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.ag-theme-alpine .ag-header-group-cell {
    font-size: 18px;
}

.ag-theme-alpine .ag-header-cell {
    font-size: 14px;
    font-weight: normal;
}

.ag-theme-alpine {
    --ag-grid-size: 3px;
    --ag-list-item-height: 20px;
    --ag-row-height: 20px !important;
    --ag-cell-horizontal-padding: 6px !important;
    --ag-cell-horizontal-border: 1px solid rgba(0, 0, 0, 0.35) !important;
    --ag-row-border-color: rgba(0, 0, 0, 0.35) !important;
    --ag-row-border-style: solid !important;
    --ag-row-border-width: 1px !important;
}

.cost-input[type="number"] {
  -moz-appearance: textfield;
}

.color-red {
  color: rgb(239, 68, 68) !important;
}
